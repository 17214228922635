import { Theme, createMuiTheme } from "@material-ui/core";
import { grey, teal } from "@material-ui/core/colors";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import defaultTheme from "./defaultTheme";

// inspired from material-ui sourcecode
const shadowKeyUmbraOpacity = 0.2;
const shadowKeyPenumbraOpacity = 0.14;
const shadowAmbientShadowOpacity = 0.12;

function createShadow(...px: number[]) {
  return [
    `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px rgba(0,0,0,${shadowKeyUmbraOpacity})`,
    `${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px rgba(0,0,0,${shadowKeyPenumbraOpacity})`,
    `${px[8]}px ${px[9]}px ${px[10]}px ${px[11]}px rgba(0,0,0,${shadowAmbientShadowOpacity})`,
  ].join(",");
}

const rightShadow = createShadow(3, 0, 3, -2, 3, 0, 4, 0, 1, 0, 8, 0);

export type CustomTheme = Theme & {
  customShadows: {
    rightShadow: string;
  };
  customPalette: {
    comparisonColors: string[];
  };
};

const primaryColorChoices = {
  main: teal[700],
  light: "#fff",
  dark: "#388E3B",
};

const palette: PaletteOptions = {
  primary: primaryColorChoices,
  background: {
    default: "#fff"
  },
  text:{
    primary: "#373134",
    secondary: grey[500],
  },
};

export const theme: CustomTheme = createMuiTheme(
  defaultTheme, {
    palette,
    customShadows: { rightShadow },
    customPalette: { comparisonColors: ["#f6f6f8", "#e8e7ec", "#cfced2"] },//
    overrides: {
      MuiOutlinedInput: {
        root: {
          // This is necessary because the old element-ai primary color shows here if we don't override it.
          // https://stackoverflow.com/questions/58113579/how-do-i-override-hover-notchedoutline-for-outlinedinput
          "&:hover $notchedOutline": {
            borderColor: primaryColorChoices.main,
          },
          // TODO: this style triggers error in console - recheck it and fix
          input: {
            padding: "8px 10px"
          }
        },
      },
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: "black",
        },
      },
    },
  }
) as CustomTheme;

export default theme;
