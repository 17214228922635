import React from "react";
import { apiClientJava } from "../../../state";
import { Props, State } from "./HomePage.types";

export const homePageContext = React.createContext<any>({});
export class HomePageProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.getReleaseNotesItems = this.getReleaseNotesItems.bind(this);
    this.getWhatsNewItems = this.getWhatsNewItems.bind(this);

    this.state = {
      context: {
        releaseNotesItems: [],
        whatsNewItems: [],
        getReleaseNotesItems: this.getReleaseNotesItems,
        getWhatsNewItems: this.getWhatsNewItems,
      },
    };
  }

  async getWhatsNewItems() {
    const response: any = await apiClientJava.getWhatsNew();
    this.setState((prevState: State) => {
      return {
        context: {
          ...prevState.context,
          whatsNewItems: response,
        },
      };
    });
  }

  async getReleaseNotesItems() {
    const response: any = await apiClientJava.getReleaseNotes();
    this.setState((prevState: State) => {
      return {
        context: {
          ...prevState.context,
          releaseNotesItems: response,
        },
      };
    });
  }

  render(): JSX.Element {
    return (
      <homePageContext.Provider value={this.state.context}>
        {this.props.children}
      </homePageContext.Provider>
    );
  }
}
