import { Filters } from "../state/job/types";
import { AlertColor } from '../types/models';

export const AppRoutes = {
  Main: "/",
  Start: "/",
  Admin: "/stores/AdminPage",
  AdminAdd: "/stores/AdminPage/Add",
  Dashboard: "dashboard",
  DashboardOld: "dashboard-old",
  ProtoPog: "proto-pog",
  Customize: "customize",
  Compare: "review/compare",
  Assortment: "review/assortment",
  Visual: "review/visual",
  ReleaseNotes: "/release-notes",
  WhatsNew: "/whats-new",
};

export const orderMap: { [key: string]: number } = {
  'BLUE': 1,
  'GREEN': 2,
  'YELLOW': 3,
  'PURPLE': 4,
  'ORANGE': 5,
  'RED': 6
};

export const UrlRoutes: { [key: string]: string } = {
  Dashboard: `/store/:storeId/${AppRoutes.Dashboard}`,
  DashboardOld: `/store/:storeId/${AppRoutes.DashboardOld}`,
  ProtoPog: `/store/:storeId/${AppRoutes.ProtoPog}`,
  Customize: `/store/:storeId/aisle/:aisleCode/${AppRoutes.Customize}`,
  Compare: `/store/:storeId/aisle/:aisleCode/${AppRoutes.Compare}`,
  Assortment: `/store/:storeId/aisle/:aisleCode/${AppRoutes.Assortment}`,
  Visual: `/store/:storeId/aisle/:aisleCode/${AppRoutes.Visual}`,
};

export const allRoutes = [
  UrlRoutes.Customize,
  UrlRoutes.Compare,
  UrlRoutes.Assortment,
  UrlRoutes.Visual,
  UrlRoutes.ProtoPog,
  UrlRoutes.Dashboard,
  UrlRoutes.SearchResult,
  AppRoutes.Main
];

export const AuthRoutes = {
  Login: "/auth/login",
  Callback: "/auth/callback",
};

export const MAX_SHELF_BLOCKS = 2;

export const FD_DWO_STATUS_VALUES = ["FD", "DWO"];

export const ResultStatus = {
  DoneNoSolution: "done_no_solution",
  Failed: "failed",
  Done: "done",
  Queue: "queued",
  Started: "started",
  Infeasible: "infeasible",
  Killed: "killed",
};

export const AisleCodesForCategories = {
  ShowerHeads: "63",
  CleaningChemicals: "53",
};

export const colors = [
  "#FF6F6F",
  "#8F7CFF",
  "#00E24D",
  "#FFEB84",
  "#CB0000",
  "#36F1E6",
  "#A00090",
  "#583E25",
  "#E585DC",
  "#25075B",
  "#a0cac4",
  "#0f7752",
];

export const colorsWOS = {
  'WOS < 1': '#B71C1C',
  '1 <= WOS < 2': '#FF8A80',
  '2 <= WOS < 5': '#FFCCBC',
  '5 <= WOS < 26': '#BBDEFB',
  '26 <= WOS < 52': '#42A5F5',
  'WOS >= 52': '#0D47A1',
};

export const colorsDGM = {
  'DGM < 0%': '#B71C1C',
  '0% <= DGM < 5%': '#FF8A80',
  '5% <= DGM < 15%': '#FFCCBC',
  '15% <= DGM < 25%': '#BBDEFB',
  '25% <= DGM < 50%': '#42A5F5',
  'DGM >= 50%': '#0D47A1',
};

export const FOOTER_HEIGHT = {
  laptop: 80,
  desktop: 80,
};

export const STEP_AISLE_HEIGHT = 12;

export const MERCH_NOTES_TEXT = [
  "The POG is Merchandised by Application (Plant Accessories, Indoor Planters, Plant Stands and Watering Cans/Spray Bottles) followed by Colour/Style (Light to Dark) then by Size (Large to Small) and finally byPrice (High to Low)",
  "Plant Accessories is merchandised by Application (Wall Brackets/Shepherd Hooks, Plant Rocks/Stones, Terrariums and Water Meters). Within each Application the assortment is merchandised by Colour/Style(Light to Dark) and Price (High to Low)",
  "Indoor Planters is merchandised by Application (Indoor Decorative Planters, Indoor Basic Planters, Plastic, Clay Planters). Within each Application the assortment is merchandised by Family/Style/Colour (Dark toLight) and Size (Large to Small)",
  "Watering Cans/Spray Bottles have been merchandised by Size (Large to Small) and Price (High to Low)",
  "Plant Stands is merchandised by Size (Largest to Smallest) then by Price (High to Low)",
  "Some watering cans can be merchandised by crossing spouts over each other when you have 2 side by side facings to increase shelf space",
];
export const filtersToNamesMapping: { [key: string]: string } = {
  [Filters.PrimaryFillIssue]: "postVisualizer.filters.primaryFillIssues",
  [Filters.MultipleHomes]: "postVisualizer.filters.multipleHomes",
  [Filters.DWOFD]: "postVisualizer.filters.fdDwo",
  [Filters.MoreThanOne]: "postVisualizer.filters.more1Facing",
  [Filters.ACTTDSD]: "postVisualizer.filters.act",
  [Filters.New]: "postVisualizer.filters.new",
  [Filters.CrossMerch]: "postVisualizer.filters.crossMerch",
};


export const warningToTitleAndDescriptionMapping: {
  [key: string]: { title: string; description: string };
} = {
  heavy_product_height_warnings: {
    title: "postVisualizer.alert.heavyProductHeightTitle",
    description: "postVisualizer.alert.heavyProductHeightDesc",
  },
  product_packaging_warnings: {
    title: "postVisualizer.alert.productPackagingTitle",
    description: "postVisualizer.alert.productPackagingDesc",
  },
  missing_blocking_data_warnings: {
    title: "postVisualizer.alert.missingBlockingTitle",
    description: "postVisualizer.alert.missingBlockingDesc",
  },
  product_missing_blocking_data_warnings: {
    title: "postVisualizer.alert.productMissingBlockingDataTitle",
    description: "postVisualizer.alert.productMissingBlockingDataDesc",
  },
  linear_whitespace_warnings: {
    title: "postVisualizer.alert.linearWhiteSpaceTitle",
    description: "postVisualizer.alert.linearWhiteSpaceDesc",
  },
  empty_fixture_warnings: {
    title: "postVisualizer.alert.emptyFixtureTitle",
    description: "postVisualizer.alert.emptyFixtureDesc",
  },
  too_much_supply_warnings: {
    title: "postVisualizer.alert.tooMuchSupplyTitle",
    description: "postVisualizer.alert.tooMuchSupplyDesc",
  },
  excluded_product_warnings_no_fit: {
    title: "postVisualizer.alert.excludedProductNoFitTitle",
    description: "postVisualizer.alert.excludedProductNoFitDesc",
  },
  excluded_product_warnings_too_tight: {
    title: "postVisualizer.alert.excludedProductTooTightTitle",
    description: "postVisualizer.alert.excludedProductTooTightDesc",
  },
  move_basket_to_shelf_warnings: {
    title: "postVisualizer.alert.moveBasketToSelfTitle",
    description: "postVisualizer.alert.moveBasketToSelfDesc",
  },
  move_crossbar_to_peg_warnings: {
    title: "postVisualizer.alert.moveCrossbarToPegTitle",
    description: "postVisualizer.alert.moveCrossbarToPegDesc",
  },
  weight_restriction_warnings: {
    title: "postVisualizer.alert.weightRestrictionTitle",
    description: "postVisualizer.alert.weightRestrictionDesc",
  },
  fd_dwo_bincap_warnings: {
    title: "postVisualizer.alert.fdDwoBincapTitle",
    description: "postVisualizer.alert.fdDwoBincapDesc",
  },
  safety_restriction_warnings: {
    title: "postVisualizer.alert.safetyRestrictionTitle",
    description: "postVisualizer.alert.safetyRestrictionDesc",
  },
  assortment_sweep_warnings: {
    title: "postVisualizer.alert.assortmentSweepTitle",
    description: "postVisualizer.alert.assortmentSweepDesc",
  }
};
export const userActionAlertTypes = ["move_basket_to_shelf_warnings", "move_crossbar_to_peg_warnings"];
export const excludedProductAlertTypes = ["excluded_product_warnings_no_fit", "excluded_product_warnings_too_tight"];
export const criticalAlertTypes = ["weight_restriction_warnings", "safety_restriction_warnings", "assortment_sweep_warnings"];
export const alertColor: AlertColor = {
  critical: {
    bg: "rgba(247, 72, 68, 0.4)",
    border: "#ED2626"
  },
  nonCritical: {
    bg: "#FECE95",
    border: "#C7802C"
  }
}

export const defaultImgURL =
  "https://media-pre.canadiantire.ca/ADF_SKU_IMAGES_RENAMED/";

export const maxPogVersions = 5;

export const EPSILON = 0.01;

export const STRIKE_ZONE_TOP = 60;
export const STRIKE_ZONE_BOTTOM = 36;
export const APIMSUBSCRIPTION_DEFAULT_VALUE = '1111111'

export const ARROW_MOVE_VALUE = 0.75;
export const ARROW_MOVE_VALUE_PEGBOARD = 1;
export const ARROW_MOVE_INTERVAL = 500;

export const DAFULT_VENDOR_PEGBOARD = "madix";

export const EXTRA_ESL_HEIGHT_OUTSIDE_FACING = 1.65 - 0.325;

export const MAX_VALUE_MIN_BINCAP = 999;
export const MAX_VALUE_MAX_BINCAP = 999;

export const MAX_VALUE_LINEAR_FEET = 999;
export const MIN_VALUE_LINEAR_FEET = 1;

export const VALIDATION_PATTERN = {
  POG_NAMES: /^[a-zA-Z ']+$/,
  POG_NUM: /^\d+[a-zA-Z]+$/,
  PRODUCT_CLASS: /^[0-9]{1,3}$/,
  AISLE_NUM: /^[0-9]{1,3}$/,
  ALLOWED_POG_SEARCH_SYMBOLS: /[a-zA-Z0-9 àâäèéêëîïôœæùûüÿçìÀÂÄÈÉÊËÎÏÔŒÆÙÛÜŸÇÌ&\-+',./:()]/g,
};

export const NEWPAGE_QUICK_LINKS = ["Beacon", "CT Hub"];
export const SAMEPAGE_QUICK_LINKS = ["Release Notes", "What's New"];
export const TETRIS_HELP = "Tetris Help";

export const STORAGE_NAMES = {
  STORE_NUMBER: 'storeNumber',
  JOB_ID_PARAMS: 'JobIdParams'
}

export const POG_GROUP_ACTIONS = {
  OPEN_MERCH_GUIDES: 'openMerchGuides',
  VIEW: 'view',
  PRINT_WITH_MY_DATA: 'printWithMyData'
}

export const lgMaxContentWidth = '1440px';